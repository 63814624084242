import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function ScrollToTop() {
    const {pathname, hash} = useLocation();
    useEffect(() => {
        if(!hash) {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    return null;
}