import React, {useEffect, useState} from 'react';
import logo from "../assets/icons/logo_nicolaus.svg";
import IconMenu from "../assets/svg/iconMenu";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import {Helmet} from "react-helmet";
import {api} from "../services/api";
import {Spinner} from "../components/spinner/Spinner";
import Article from "../components/article-item";
import {dateFromat} from "../services/utils";
import {useLocation, useParams} from "react-router-dom";
function ArticlesPage() {

    const params = useParams();

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [articlesCache, setArticlesCache] = useState([]);
    const [articles, setArticles] = useState([]);

    const location = useLocation();

    useEffect(() => {
        const getArticles = async () => {
            setIsLoading(true);
            if (articlesCache.length) {
                setArticles(articlesCache.filter(item => params.type ? item.type === params.type : item));
            } else {
                let response = await api.fetchArticles();
                let results= response.results.map((item) => {
                    return {
                        date: dateFromat(item.created_at),
                        type: item.category && item.category.title,
                        title: item.title,
                        slug: item.slug
                    }
                })
                setArticlesCache(results);
                setArticles(results.filter(item => params.type ? item.type === params.type : item));
            }
            setIsLoading(false);
        };
        getArticles();
    }, [location]);

    return (
        <div className="article-page-wrapper">
            <Helmet>
                <title>Articles {'' ?? params.type}</title>
            </Helmet>
            <Sidebar opened={isOpen} onClose={() => setIsOpen(false)}/>
            <div className="article-main">
                <div className="article-header">
                    <button className="menu-open" onClick={() => setIsOpen(true)}>
                        <IconMenu/>
                    </button>
                    <div className="logo"><a href="/"><img src={logo} alt="Nicolaus"/></a></div>
                </div>
                <div className="article-content">
                    {isLoading && <Spinner/>}
                    {!isLoading && <div className="articles-list">
                        {articles.map(({title, date, type, slug}, index) => {
                            return <Article title={title} date={date} type={type} key={index} slug={slug}/>
                        })}
                    </div>}
                </div>
                <Footer/>
            </div>
        </div>
    );
};
export default ArticlesPage;
