import React from 'react';
import './spinner.css';


export const Spinner = ({ className = '', classNameCircle = '', }) => {

  return (
    <div className={className}>
      <div className={` ${classNameCircle || 'circle-loader'} loader ease-linear`} />
    </div>
  );
};
