import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={isActive ? 'accordion-item expanded' : 'accordion-item'} onClick={() => setIsActive(!isActive)}>
            <div className="accordion-title">{title}</div>
            <div className="accordion-content">{content}</div>
        </div>
    );
};
export default Accordion;