import React from 'react';
import {HashLink as Link} from "react-router-hash-link";

const Footer = () => {
    return (
        <div className="footer-bottom">
            <p className="footer-copyright">© 2023 Nicolaus Inc. All Rights Reserved.</p>
            <ul className="footer-menu">
                <li><Link to="/about">ABOUT THE PROJECT</Link></li>
                <li><Link to="/#faq">FAQ</Link></li>
                <li><Link to="/join-the-team">JOIN THE TEAM</Link></li>
                <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
            </ul>
        </div>
    );
};
export default Footer;