import React, {useContext, useState} from "react";
// context
import {SearchContext} from "../App";
// hook
import {useNavigate} from "react-router-dom";
import {Spinner} from "./spinner/Spinner";

export const SearchGoogleField = () => {
    const [text, setText] = useState("");
    const [request, setRequest] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(SearchContext);
    const navigate = useNavigate();
    const handleClick = () => {
        context.toggleData({request: "", answer: ""});
        setIsLoading(true);
        fetch('/api/gemini/generate_content/', {
            method: 'POST',
            body: JSON.stringify({
                text: text
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((result) => {
            const answer = result.data;
            if (answer) {
                setIsLoading(false);
                context.toggleData({request, answer});
                setText("");
                navigate("/results");
                console.log(answer);
            }
        }).catch((e) => {
            console.log("e", e.message);
            setError(e.message);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    return (
        <div className="search-main">
            <input
                type="text"
                placeholder="Ask a question"
                value={text}
                onChange={(e) => {
                    setText(e.target.value);
                    setRequest(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && text) {
                        handleClick();
                    }
                }}
            />
            <button disabled={!text} onClick={() => handleClick()} className="form-button">
                Research
                {isLoading && <Spinner/>}
            </button>
            {error && <span className="status-text text-error">{error}</span>}
        </div>
    );
};
