import "./App.css";
import {Routes, Route, useLocation} from "react-router-dom";
import { default as ArticlePage } from "./pages/ArticlePage";
import { default as LandingPage } from "./pages/LandingPage";
import React, {createContext, useContext, useEffect, useState} from "react";
import ResultPage from "./pages/ResultPage";
import ScrollToTop from "./components/scrollToTop";
import ContentPage from "./pages/ContentPage";
import ArticlesPage from "./pages/ArticlesPage";
import TagManager from 'react-gtm-module';
export const SearchContext = createContext({
  request: "",
  answer: "",
  toggleData: () => {},
});

const tagManagerArgs = {
  gtmId: 'GTM-KXGHDGMX'
}

TagManager.initialize(tagManagerArgs);

const metaPixel = () => {
  // eslint-disable-next-line no-unused-expressions
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
  // eslint-disable-next-line no-undef
  fbq('init', '927735165738466');
  // eslint-disable-next-line no-undef
  fbq('track', 'PageView');
}

function App() {
  const searchContext = useContext(SearchContext);
  const [context, setContext] = useState(searchContext);
  const toggleData = (value) => {
    setContext({
      ...context,
      request: value.request,
      answer: value.answer,
    });
  };

  const state = {
    ...context,
    toggleData: toggleData,
  };

  const location = useLocation();

  useEffect(() => {
    metaPixel();
  }, [location]);

  window.dataLayer.push({
    event: 'pageview'
  });

  return (
    <div className="App">
      <SearchContext.Provider value={state}>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/articles/" element={<ArticlesPage/>}/>
          <Route path="/articles/:type/" element={<ArticlesPage/>}/>
          <Route path="/articles/:type/:slug/" element={<ArticlePage/>}/>
          <Route path="/results" element={<ResultPage/>}/>
          <Route path="/about" element={<ContentPage page="about"/>}/>
          <Route path="/join-the-team" element={<ContentPage page="join_the_team"/>}/>
          <Route path="/privacy-policy" element={<ContentPage page="privacy_policy"/>}/>
        </Routes>
      </SearchContext.Provider>
    </div>
  );
}

export default App;
