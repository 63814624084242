import * as React from "react";

function IconMenu() {
    return (
        <svg id="icon_menu_burger" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="container" width="24" height="24" fill="none"/>
            <path id="default"
                  d="M140.883-692.126a.854.854,0,0,1-.629-.254.854.854,0,0,1-.254-.629.853.853,0,0,1,.254-.629.854.854,0,0,1,.629-.253h18.235a.854.854,0,0,1,.629.254.854.854,0,0,1,.254.629.853.853,0,0,1-.254.629.854.854,0,0,1-.629.253Zm0-5.747a.854.854,0,0,1-.629-.254.854.854,0,0,1-.254-.629.853.853,0,0,1,.254-.629.854.854,0,0,1,.629-.253h18.235a.854.854,0,0,1,.629.254.854.854,0,0,1,.254.629.853.853,0,0,1-.254.629.854.854,0,0,1-.629.253Zm0-5.747a.854.854,0,0,1-.629-.254A.854.854,0,0,1,140-704.5a.853.853,0,0,1,.254-.629.854.854,0,0,1,.629-.253h18.235a.854.854,0,0,1,.629.254.854.854,0,0,1,.254.629.853.853,0,0,1-.254.629.854.854,0,0,1-.629.253Z"
                  transform="translate(-138.001 710.754)" fill="#1058ff"/>
        </svg>
    );
}

export default IconMenu;