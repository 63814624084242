import React from 'react';
import { Link } from "react-router-dom";

const Article = ({ date, type, title, slug }) => {

    let category = type.toLowerCase();

    return (
        <div className="article-item">
            <div className="article-item_info">
                <span className="article-item_date">{date}</span>
                <span className="article-item_bull">&bull;</span>
                <span className="article-item_type">{type}</span>
            </div>
            <div className="article-item_title"><Link relative='route' to={`/articles/${category}/${slug}/`}>{title}</Link></div>
        </div>
    );
};
export default Article;