import React, {useCallback, useEffect, useState} from 'react';
import logo from "../assets/icons/logo_nicolaus.svg";
import Menu from "./menu";
import IconClose from "../assets/svg/iconClose";
import {api} from "../services/api";
import {Spinner} from "./spinner/Spinner";
import {Link, useParams} from "react-router-dom";

const Sidebar = ({ opened, onClose }) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('all');

    const getCategories = useCallback(async () => {
        try {
            setIsLoading(true);
            let response = await api.fetchCategories();
            setCategories(response.results);
        } catch (err) {
            throw Error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getCategories();
    }, []);

    // const cachedCategories = useMemo(() => categories, [categories]);

    const params = useParams();
    let type = params.type ? params.type : 'all';

    useEffect(() => {
        setCategory(type);
    }, [params]);

    function changeCategory(type) {
        setCategory(type);
    }

    return (
        <>
            <div className={opened ? "backdrop-shadow show" : "backdrop-shadow"} onClick={onClose}></div>
            <div className={opened ? "sidebar opened" : "sidebar"}>
                <div className="logo"><a href="/"><img src={logo} alt="Nicolaus"/></a></div>
                <Menu/>
                <hr/>
                <div className="other-topics">
                    <h3>Explore Other Topics</h3>
                    <ul className="articles-topics">
                        <li className={category === 'all' ? 'active' : ''} onClick={() => changeCategory('all')}>
                            <Link to="/articles/" onClick={onClose}>All</Link>
                        </li>
                        {isLoading && <Spinner/>}
                        {categories.map((item) => {
                            return <li className={item.title.toLowerCase() === category.toLowerCase() ? 'active' : ''} key={item.id} onClick={() => changeCategory(item.title)}><Link to={`/articles/${item.title}`} onClick={onClose}>{item.title}</Link></li>
                        })}
                    </ul>
                </div>
                <button className="sidebar-close" onClick={onClose}>
                    <IconClose/>
                </button>
            </div>
        </>
    );
};
export default Sidebar;