import React from 'react';
import {HashLink as Link} from "react-router-hash-link";

const Menu = ({onLinkClick}) => {

    return (
        <ul className="menu">
            <li><Link smooth to="/#how-it-works" onClick={onLinkClick}>HOW IT WORKS</Link></li>
            <li><Link smooth to="/#use-case" onClick={onLinkClick}>USE CASES</Link></li>
            <li><Link smooth to="/#articles" onClick={onLinkClick}>ARTICLES</Link></li>
            <li><Link smooth to="/#faq" onClick={onLinkClick}>FAQ</Link></li>
        </ul>
    );
};
export default Menu;