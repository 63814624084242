import React, {useEffect, useState} from "react";
import Article from "../components/article-item";
import nicolaus from "../assets/images/portrait_nicolaus.png";
import nicolaus2x from "../assets/images/portrait_nicolaus@2x.png";
import heliocentric from "../assets/images/heliocentric.png";
import heliocentric2x from "../assets/images/heliocentric@2x.png";
import manuscript from "../assets/images/manuscript.png";
import manuscript2x from "../assets/images/manuscript@2x.png";
import graphic_device from "../assets/images/graphic_device.png";
import graphic_device2x from "../assets/images/graphic_device@2x.png";
import twitter_icon from "../assets/icons/logo-x.svg";
import facebook_icon from "../assets/icons/logo-fb.svg";
import linkedin_icon from "../assets/icons/logo-linkedin.svg";
import logo from "../assets/icons/logo_nicolaus.svg";
import IconMenu from "../assets/svg/iconMenu";
import IconClose from "../assets/svg/iconClose";
import {SearchGoogleField} from "../components/search-field";
import Menu from "../components/menu";
import {Link} from "react-router-dom";
import {api} from "../services/api";
import {dateFromat} from "../services/utils";
import Footer from "../components/footer";
import {Spinner} from "../components/spinner/Spinner";
import Subscribe from "../components/subscribe";
import Faq from "../components/faq";
import {Helmet} from "react-helmet";

function LandingPage() {
    const pageSize = 5;
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('all');
    const [isLoading, setIsLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const [articlesTake, setArticlesTake] = useState(pageSize);

    useEffect(() => {
        const getCategories = async () => {
            setIsLoading(true);
            let response = await api.fetchCategories();
            setCategories(response.results);
            setIsLoading(false);
        };
        const getArticles = async () => {
            setIsLoading(true);
            let response = await api.fetchArticles();
            let results= response.results.map((item) => {
                return {
                    date: dateFromat(item.created_at),
                    type: item.category && item.category.title,
                    title: item.title,
                    slug: item.slug
                }
            })
            setArticles(results);
            setIsLoading(false);
        };
        getCategories();
        getArticles();
    }, []);

    function changeCategory(type) {
        setArticlesTake(pageSize);
        setCategory(type);
    }

    let articlesFiltered = articles.filter(item => 'all' !== category ? item.type === category : item);
    function loadMoreArticles() {
        if (articlesTake < articles.length) {
            setArticlesTake(articlesTake + pageSize);
        }
    }


    return (
        <div className="landing-wrapper">
            <Helmet>
                <meta name="description" content="AI-generated answers to your questions based on science, credible sources, and scientific studies."/>
            </Helmet>
            <header className="header">
                <div className="container">
                    <Menu/>
                    <button className="menu-open" onClick={() => setIsOpen(true)}>
                        <IconMenu/>
                    </button>
                </div>
            </header>
            <div className={isOpen ? "backdrop-shadow show" : "backdrop-shadow"} onClick={() => setIsOpen(false)}></div>
            <div className={isOpen ? "sidebar opened" : "sidebar"}>
                <div className="logo"><Link to="/"><img src={logo} alt="Nicolaus"/></Link></div>
                <Menu onLinkClick={() => setIsOpen(false)}/>
                <button className="sidebar-close" onClick={() => setIsOpen(false)}>
                    <IconClose/>
                </button>
            </div>
            <div className="sticky-menu">
                <button className="menu-open" onClick={() => setIsOpen(true)}>
                    <IconMenu/>
                </button>
                <div className="logo"><Link to="/"><img src={logo} alt="Nicolaus"/></Link></div>
                <Menu/>
            </div>
            <div className="main">
                <div className="container">
                    <img className="portrait" src={nicolaus} srcSet={`${nicolaus2x} 2x`} alt="nicolaus"/>
                    <h1 className="block-header"><img src={logo} alt="Nicolaus"/></h1>
                    <h2 className="sub-header">Information backed by science</h2>
                    <SearchGoogleField/>
                </div>
            </div>
            <div className="main-gap"></div>
            <hr/>
            <section id="how-it-works" className="block">
                <div className="container">
                    <h2 className="sub-header">How it works</h2>
                    <h3 className="block-header">Information backed by science</h3>
                    <ul className="block-list">
                        <li className="block-item">
                            <div className="block-item_header"><i className="block-icon icon-ask"></i><b>Ask</b></div>
                            <p>Ask anything. For example, "Does the Mediterranean diet increase life span?"</p>
                        </li>
                        <li className="block-item">
                            <div className="block-item_header"><i className="block-icon icon-learn"></i><b>Learn</b>
                            </div>
                            <p>Get a response based on actual scientific evidence</p>
                        </li>
                        <li className="block-item">
                            <div className="block-item_header"><i className="block-icon icon-source"></i><b>See the
                                sources</b></div>
                            <p>Your answer will include links to the sources</p>
                        </li>
                    </ul>
                </div>
            </section>
            <div className="block-divider"><img src={heliocentric} srcSet={`${heliocentric2x} 2x`} alt=""/></div>
            <section id="use-case" className="block">
                <div className="container">
                    <h2 className="sub-header">Use cases</h2>
                    <h3 className="block-header">Nicolaus applications</h3>
                    <ul className="block-list">
                        <li className="block-item">
                            <div className="block-item_header"><i
                                className="block-icon icon-curiosity"></i><b>Curiosity</b></div>
                            <p>See if something is an old wife's tale, or there is actual scientific data to it</p>
                        </li>
                        <li className="block-item">
                            <div className="block-item_header"><i
                                className="block-icon icon-research"></i><b>Research</b></div>
                            <p>Do a research on a subject of your choice knowing that it will be evidence-based</p>
                        </li>
                        <li className="block-item">
                            <div className="block-item_header"><i className="block-icon icon-write"></i><b>Writing</b>
                            </div>
                            <p>Academic, business, blogging — all with factual information</p>
                        </li>
                    </ul>
                </div>
            </section>
            <hr/>
            <section id="articles" className="block block-articles">
                <div className="container">
                    <div className="manuscript-block">
                        <img src={manuscript} srcSet={`${manuscript2x} 2x`} alt="manuscript"/>
                        <h2 className="sub-header">Read science-backed articles</h2>
                        <h3 className="block-header">Generated by Nicolaus</h3>
                    </div>
                    <div className="articles-wrapper">
                        <ul className="articles-categories">
                            <li className={'all' === category ? 'active' : ''} onClick={() => changeCategory('all')}>All</li>
                            {isLoading && <Spinner/>}
                            {categories.map((item) => {
                                return <li className={item.title === category ? 'active' : ''} key={item.id} onClick={() => changeCategory(item.title)}>{item.title}</li>
                            })}
                        </ul>
                        <div className="articles-list">
                            {articlesFiltered.slice(0, articlesTake).map(({title, date, type, slug}, index) => {
                                return <Article title={title} date={date} type={type} key={index} slug={slug}/>
                            })}
                        </div>
                        {articlesTake < articlesFiltered.length && <button className="link-button" onClick={() => loadMoreArticles()}>LOAD MORE</button>}
                    </div>
                </div>
            </section>
            <hr/>
            <section id="faq" className="block">
                <div className="container">
                    <h2 className="sub-header">We have the answers to your</h2>
                    <h3 className="block-header">Frequently asked questions</h3>
                    <Faq/>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="footer-top">
                        <ul className="footer-menu">
                            <li><Link to="/about">ABOUT THE PROJECT</Link></li>
                            <li><Link to="/#faq">FAQ</Link></li>
                            <li><Link to="/join-the-team">JOIN THE TEAM</Link></li>
                            <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
                        </ul>
                        <Subscribe/>
                        <div className="footer-follow">
                            <h2 className="block-header">Follow us on:</h2>
                            <ul className="footer-social_links">
                                <li><a href="/#" target="_blank">
                                    <img src={twitter_icon} alt="X"/>
                                </a></li>
                                <li><a href="/#" target="_blank">
                                    <img src={facebook_icon} alt="Facebook"/>
                                </a></li>
                                <li><a href="/#" target="_blank">
                                    <img src={linkedin_icon} alt="linked in"/>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Footer/>
                    <img className="footer-graphic_device" src={graphic_device} srcSet={`${graphic_device2x} 2x`} alt="graphic_device"/>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
