import React, {useEffect, useState} from 'react';
import logo from "../assets/icons/logo_nicolaus.svg";
import IconMenu from "../assets/svg/iconMenu";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import {Helmet} from "react-helmet";
import {api} from "../services/api";
import Markdown from "react-markdown";
import {Spinner} from "../components/spinner/Spinner";
import {useLocation} from "react-router-dom";
import LinkRenderer from "../utils/helper";
function ContentPage({page}) {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState('');

    const location = useLocation();

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            let response = await api.fetchContent(page);
            setData(response.data);
            setIsLoading(false)
        };
        getData();
    }, [location]);

    return (
        <div className="article-page-wrapper">
            {data && <Helmet>
                <title>{data.meta_title}</title>
                <meta name="description" content={data.meta_description}/>
            </Helmet>}
            <Sidebar opened={isOpen} onClose={() => setIsOpen(false)}/>
            <div className="article-main">
                <div className="article-header">
                    <button className="menu-open" onClick={() => setIsOpen(true)}>
                        <IconMenu/>
                    </button>
                    <div className="logo"><a href="/"><img src={logo} alt="Nicolaus"/></a></div>
                </div>
                <div className="article-content">
                    {isLoading && <Spinner/>}
                    <Markdown components={{ a: LinkRenderer }}>{!isLoading && data && data.description}</Markdown>
                </div>
                <Footer/>
            </div>
        </div>
    );
};
export default ContentPage;
