import React, {useEffect, useState} from 'react';
import logo from "../assets/icons/logo_nicolaus.svg";
import IconMenu from "../assets/svg/iconMenu";
import {SearchGoogleField} from "../components/search-field";
import Markdown from "react-markdown";
import {api} from "../services/api";
import {useParams} from "react-router-dom";
import {dateFromat} from "../services/utils";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import {Helmet} from "react-helmet";
import LinkRenderer from "../utils/helper";
import Share from "../components/share";

function ArticlePage() {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [article, setArticle] = useState('');

    const params = useParams();

    useEffect(() => {
        const getArticle = async () => {
            setIsLoading(true)
            let response = await api.fetchArticle(params.slug);
            setArticle(response);
            setIsLoading(false)
        };
        params.slug && getArticle();
    },[params.slug]);

    return (
        <div className="article-page-wrapper">
            <Helmet>
                <title>{article.meta_title}</title>
                <meta name="description" content={article.meta_description}/>
                <meta property="og:type" content="article"/>
                <meta property='og:title' content={article.meta_title}/>
                <meta property="og:site_name" content="Nicolaus"/>
                <meta property='og:url' content={window.location.href}/>
                <meta property='og:description' content={article.meta_description}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:widgets:new-embed-design" content="on"/>
                <meta name='twitter:title' content={article.meta_title}/>
                <meta name='twitter:description' content={article.meta_description}/>
                <link rel="canonical" href={window.location.href}/>
            </Helmet>
            <Sidebar opened={isOpen} onClose={() => setIsOpen(false)}/>
            {!isLoading && <div className="article-main">
                <div className="article-header">
                    <button className="menu-open" onClick={() => setIsOpen(true)}>
                        <IconMenu/>
                    </button>
                    <div className="logo"><a href="/"><img src={logo} alt="Nicolaus"/></a></div>
                </div>
                <div className="article-content">
                    <div className="article-item_info">
                        <span className="article-item_date">{article && dateFromat(article.created_at)}</span>
                        <span className="article-item_bull">&bull;</span>
                        <span className="article-item_type">{article.category && article.category.title}</span>
                    </div>
                    <h1 className="article-item_title">{article && article.title}</h1>
                    <Markdown components={{ a: LinkRenderer }}>{article && article.description}</Markdown>
                    <Share title={article.title} description={article.description}/>
                    <SearchGoogleField/>
                </div>
                <Footer/>
            </div>}
        </div>
    );
}
export default ArticlePage;
