import React, {useState} from 'react';
import {Spinner} from "./spinner/Spinner";
import {validateEmail} from "../utils/helper";

const Subscribe = () => {

    const [email, setEmail] = useState('');
    const [errorSubscribe, setErrorSubscribe] = useState('');
    const [successSubscribe, setSuccessSubscribe] = useState('');
    const [isSubscribeLoading, setSubscribeLoading] = useState(false);

    function subscribeEmail() {
        setSuccessSubscribe("");
        setErrorSubscribe("");
        if (!validateEmail(email)) {
            setErrorSubscribe("Please check your email.");
            return;
        }
        setSubscribeLoading(true);
        fetch('/api/footer/subscribe/ ', {
            method: 'POST',
            body: JSON.stringify({
                email: email
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => {
                let data = response.json();
                if (!response.ok && response.status !== 400) {
                    throw Error(response.statusText);
                }
                return data;
            })
            .then((result) => {
                setEmail("");
                if(result.error) {
                    setErrorSubscribe(result.error);
                } else {
                    setSuccessSubscribe("Thank you! You successfully subscribed.");
                }
                console.log(result);
            }).catch((e) => {
            console.log(e);
            console.log("e", e.message);
            setErrorSubscribe(e.message);
        }).finally(() => {
            setSubscribeLoading(false);
        });
    }

    return (
        <div className="footer-subscribe">
            <h2 className="block-header">Get one science-backed cool short article daily.</h2>
            <div className="footer-subscribe_input-wrapper">
                <div className={errorSubscribe ? "input-wrapper error-icon" : "input-wrapper"}>
                    <input type="email"
                           placeholder="Email Address"
                           value={email}
                           className={errorSubscribe && "error"}
                           pattern="email"
                           onChange={(e) => {
                               setEmail(e.target.value);
                           }}
                           onKeyDown={(e) => {
                               if (e.key === "Enter" && email) {
                                   subscribeEmail();
                               }
                           }}/>
                    {errorSubscribe && <span className="status-text text-error">{errorSubscribe}</span>}
                    {successSubscribe && <span className="status-text text-success">{successSubscribe}</span>}
                </div>
                <button className="form-button" onClick={() => subscribeEmail()}>
                    Subscribe
                    {isSubscribeLoading && <Spinner/>}
                </button>
            </div>
        </div>
    );
};
export default Subscribe;