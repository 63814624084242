import React, {useState} from "react";
// context
import { SearchContext } from "../App";
// components
import { SearchGoogleField } from "../components/search-field";
import Markdown from "react-markdown";
// styles
import IconMenu from "../assets/svg/iconMenu";
import logo from "../assets/icons/logo_nicolaus.svg";
import {Helmet} from "react-helmet";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import LinkRenderer from "../utils/helper";
import Share from "../components/share";
const ResultPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
      <SearchContext.Consumer>
        {(value) => (
            <div className="article-page-wrapper">
                <Helmet>
                    <title>{value.request}</title>
                    <meta name="description" content={value.request}/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:site_name" content="Nicolaus"/>
                    <meta property='og:title' content={value.request}/>
                    <meta property='og:url' content={window.location.href}/>
                    <meta property='og:description' content={value.request}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:widgets:new-embed-design" content="on"/>
                    <meta name='twitter:title' content={value.request}/>
                    <meta name='twitter:description' content={value.request}/>
                    <link rel="canonical" href={window.location.href}/>
                </Helmet>
              <Sidebar opened={isOpen} onClose={() => setIsOpen(false)}/>
              <div className="article-main">
                <div className="article-header">
                  <button className="menu-open" onClick={() => setIsOpen(true)}>
                    <IconMenu/>
                  </button>
                  <div className="logo"><a href="/"><img src={logo} alt="Nicolaus"/></a></div>
                </div>
                <div className="article-content">
                  <h1 className="article-item_title">{value.request}</h1>
                  <Markdown components={{a: LinkRenderer}}>{value.answer}</Markdown>
                  <Share social={false} title={value.request} description={value.answer}/>
                  <SearchGoogleField/>
                </div>
                <Footer/>
              </div>
            </div>
        )}
      </SearchContext.Consumer>
  );
};

export default ResultPage;
