import React, {useEffect, useState} from 'react';
import Accordion from "./accordion";
import {api} from "../services/api";
import {Spinner} from "./spinner/Spinner";

const Faq = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState('');

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            let response = await api.fetchContent('faq');
            setData(response.results);
            setIsLoading(false)
        };
        getData();
    }, []);

    return (
        <div className="block-accordion">
            {isLoading && <Spinner/>}
            {data && data.map(({title, description}, index) => {
                return <Accordion title={title} content={description} key={index}/>
            })}
        </div>
    );
};
export default Faq;