import * as React from "react";

function IconClose() {
    return (
        <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="container" width="24" height="24" fill="none"/>
            <path id="default"
                  d="M236.231-722.108l-8,8a1.142,1.142,0,0,1-.823.335,1.114,1.114,0,0,1-.838-.335,1.131,1.131,0,0,1-.343-.831,1.131,1.131,0,0,1,.343-.831l8-8-8-8a1.142,1.142,0,0,1-.335-.823,1.114,1.114,0,0,1,.335-.838,1.131,1.131,0,0,1,.831-.343,1.131,1.131,0,0,1,.831.343l8,8,8-8a1.142,1.142,0,0,1,.823-.335,1.114,1.114,0,0,1,.838.335,1.131,1.131,0,0,1,.343.831,1.131,1.131,0,0,1-.343.831l-8,8,8,8a1.142,1.142,0,0,1,.335.823,1.114,1.114,0,0,1-.335.838,1.131,1.131,0,0,1-.831.343,1.131,1.131,0,0,1-.831-.343Z"
                  transform="translate(-224.231 735.77)" fill="#1058ff"/>
        </svg>
    );
}

export default IconClose;