import twitter_icon from "../assets/icons/logo-x.svg";
import facebook_icon from "../assets/icons/logo-fb.svg";
import linkedin_icon from "../assets/icons/logo-linkedin.svg";
import share_icon from "../assets/icons/icon_share.svg";
import React from "react";

const Share = ({title, description, social= true}) => {
    return (
        <div className="article-share">
            <h3 className="block-header">Share:</h3>
            <ul className="article-share_links">
                {social &&
                    <>
                        <li><a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} rel="noreferrer"
                               target="_blank">
                            <img src={twitter_icon} alt="X"/>
                        </a></li>
                        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} rel="noreferrer"
                               target="_blank">
                            <img src={facebook_icon} alt="Facebook"/>
                        </a></li>
                        <li><a
                            href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} rel="noreferrer"
                            target="_blank">
                            <img src={linkedin_icon} alt="linked in"/>
                        </a></li>
                    </>
                }
                <li>
                    <button onClick={() => navigator.clipboard.writeText(title + "\n" + description)}>
                        <img src={share_icon} alt="Share"/>
                    </button>
                </li>
            </ul>
        </div>
    );
};
export default Share;