const API_URL = '/api/article';
export const api = {
    fetchCategories: async (page = 1, pageSize = 20) => {
        const response = await fetch(
            `${API_URL}/categories/?page=${page}&page_size=${pageSize}`
        );
        return await response.json();
    },
    fetchArticles: async (page = 1, pageSize = 20) => {
        const response = await fetch(
            `${API_URL}/articles/?page=${page}&page_size=${pageSize}`
        );
        return await response.json();
    },
    fetchArticle: async (id) => {
        const response = await fetch(
            `${API_URL}/articles/${id}/`
        );
        return await response.json();
    },
    fetchContent: async (content) => {
        const response = await fetch(
            `api/footer/${content}/`
        );
        return await response.json();
    },
}